import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

export default function contact() {
    return (
        <div>
            <Navbar />
            <SEO />
            <div className="has-background-dark has-text-centered columns">
                <div className="column"></div>
                <div className="column is-half">
                    <StaticImage 
                        src="../images/TopTalentLogo-green.png" 
                        alt="Top Talent and pyramid logo" 
                        width={100}
                    />
                    <h3 className="title has-text-primary">Ready to take your company's talent to the top?</h3>
                    <h4 className="subtitle has-text-primary">Schedule your FREE consulation by filling out the form below.</h4>
                    <p className="has-text-primary">We will respond within 2 business days.</p>

                    <form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/thank-you">
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="py-5 has-text-left">
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label has-text-primary">Name (required)</label>
                                        <input name="name" className="input has-text-dark" type="text" placeholder="e.g. Alex Smith" />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label has-text-primary">Company (required)</label>
                                        <input name="company" className="input has-text-dark" type="text" placeholder="Company Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label has-text-primary">Email (required)</label>
                                        <input name="email" className="input has-text-dark" type="email" placeholder="e.g. alex.smith@yourcompany.com" />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label has-text-primary">Service(s)</label>
                                        <div className="control">
                                            <div name="service" className="select">
                                                <select>
                                                    <option>Coaching</option>
                                                    <option>Leadership Development</option>
                                                    <option>Talent & Performance Management</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label has-text-primary">Message for Top Talent</label>
                                <div className="control">
                                    <textarea name="message" className="textarea" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-link" type="submit">Send</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="column pb-5"></div>
            </div>
            <Footer />
        </div>
    )
}
